/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useEffect, useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'

import Navbar from '@monorepo-amais/commons/components/Navbar'

import Footer from '@monorepo-amais/commons/components/Footer'

import LogoPositivo from '@monorepo-amais/commons/components/Icons/IRNPositivo'
import LogoNegativo from '@monorepo-amais/commons/components/Icons/IRNNegativo'
import { isClientPersisted } from '@monorepo-amais/commons/utils/sessionControl'

import theme from './../theme'
import setMenuItens from './menuList/menuList'
import './font-style.css'
import '@monorepo-amais/commons/assets/font/stylesheet.css'
import { graphql, useStaticQuery } from 'gatsby'
import { fixRequiredBubbleBehideNavbar } from '@monorepo-amais/commons/utils/fixBrowser'

const ContainerFlex = styled.div`
	/* flex ta quebrando com o react-slick D: */
	/* display: flex;
	flex-direction: column; */
	position: relative;
	/* min-height: 98vh; */

	@media (max-width: 1023px) {
		padding-top: 80px;
	}

	/* filter: ${props => (props.backdrop ? 'blur(2px) opacity(0.4) drop-shadow(0px 0px 0px black)' : 'none')}; */
`

/**
 * Componente "pai" de todas as telas.
 *
 * @param children são os componentes renderizados dentro deles
 * @param isClient Booleano que controla o estado da Navbar, cliente ou médico
 *
 * @todo implementar prop types pra forçar o recebimento de props?
 */
const Layout = ({ children, onClick, ...rest }) => {
	let isClient = true
	if (typeof window !== 'undefined') {
		isClient = isClientPersisted()
	}
	// Guarda o estado de backdrop para sair do menu "Todas a categorias" (não utilizado no layout atual)
	// const [backdrop, setBackdrop] = React.useState(false)
	// const toggleBackdrop = () => setBackdrop(!backdrop) || console.log('toggle bakcdrop')

	// Guarda a referencia da navbar, que é usada para calcular a altura do padding do container principal
	const [navbar, setNavbar] = useState()
	// Guarda a altura do padding do container principal, default é 160px
	const [containerPadding, setContainerPadding] = useState(160)

	const data = useStaticQuery(graphql`
		{
			site {
				siteMetadata {
					title
				}
			}
			regionais: cosmicjsInformacoesEstaticas(title: { eq: "Regionais" }) {
				metadata {
					telefones {
						estado
						telefone
						sigla
						link_whatsapp
					}
				}
			}
			#usando alias de footer: pra n dar conflito
			footer: cosmicjsInformacoesEstaticas(title: { eq: "Footer" }) {
				metadata {
					responsavel_tecnico
					telefones_footer {
						estado
						telefone
						whatsapp_telefone
						link_whatsapp
					}
					politica_de_privacidade {
						url
					}
					politica_de_autoridade {
						url
					}
					politicas
					fonte_de_financiamento
					redes_sociais {
						nome
						link
					}
					loja {
						android {
							imagem {
								url
								imgix_url
							}
							link
						}
						apple {
							imagem {
								url
								imgix_url
							}
							link
						}
					}
				}
			}
			contatos: cosmicjsInformacoesEstaticas(slug: { eq: "informacoes-de-contato" }) {
				metadata {
					titulo
					subtitulo
					canal_de_contato {
						icone {
							url
							imgix_url
						}
						titulo
						texto
					}
				}
			}
			contatosAgendar: cosmicjsInformacoesEstaticas(slug: { eq: "contatos-agendar" }) {
				metadata {
					titulo
					subtitulo
					horarios
					canal_de_contato {
						icone {
							url
							imgix_url
						}
						titulo
						texto
					}
				}
			}
			resultadoExame: cosmicjsInformacoesEstaticas(title: { eq: "Resultado de exame" }) {
				metadata {
					link_cliente
					link_medico
				}
			}
		}
	`)

	const { footer, regionais, site, contatos, contatosAgendar, resultadoExame } = data
	const menuItens = useCallback(urlResults => setMenuItens(isClient, urlResults), [isClient])

	useEffect(() => {
		fixRequiredBubbleBehideNavbar()
	}, [])
	// Toda vez que a navbar muda de referência, é calculado o tamanho do padding
	useEffect(() => {
		setContainerPadding(navbar && navbar.current ? navbar.current.clientHeight : 160)
	}, [navbar])

	const urlCareers = useMemo(
		() => (isClient ? 'https://trabalheconosco.vagas.com.br/grupo-fleury' : 'https://www.questionpro.com/t/ALQtnZes32'),
		[isClient]
	)

	return (
		<ThemeProvider theme={theme}>
			<>
				<Navbar
					menuItens={menuItens}
					isClientFromLayout={isClient}
					regionais={regionais}
					setNavbarRef={e => setNavbar(e)}
					logoPositivo={<LogoPositivo />}
					logoNegativo={<LogoNegativo />}
					theme={theme}
					linkCliente={resultadoExame.metadata.link_cliente}
					linkMedico={resultadoExame.metadata.link_medico}
					contatos={contatos}
				/>
				{/* <ContainerFlex backdrop={backdrop} onClick={onClick} style={{ paddingTop: containerPadding }}> */}
				<ContainerFlex onClick={onClick} style={{ paddingTop: containerPadding }}>
					{children}
				</ContainerFlex>
				{/* TODO pegar informacao do cosmic */}
				<Footer
					{...footer}
					title={site.siteMetadata.title}
					urlCareers={urlCareers}
					aboutText={'Sobre o Instituto de Radiologia'}
					contatos={contatos}
					contatosAgendar={contatosAgendar}
				/>
			</>
		</ThemeProvider>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired
}

export default Layout
